<template>
  <section>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="top">
        <div>
          <p class="res_title">
            Result Information
          </p>
        </div>
        <div>
          <p class="res_subtitle">
            {{ res.basic.studentName }}
          </p>
          <p class="res_subtitle">
            Class: {{ res.basic.studentClass }}
          </p>
        </div>
      </section>
      <section class="content">
        <div class="res_grid">
          <VCard class="res_card shadow">
            <p class="res_main_title pb-3">
              Profile
            </p>
            <div class="res_content">
              <div class="span2">
                <p class="res_main_subtitle">
                  Name
                </p>
                <p class="res_main_txt">
                  {{ res.basic.studentName }}
                </p>
              </div>
              <div>
                <p class="res_main_subtitle">
                  Adm. No.
                </p>
                <p class="res_main_txt">
                  {{ res.basic.admNo }}
                </p>
              </div>
              <div>
                <p class="res_main_subtitle">
                  Term
                </p>
                <p class="res_main_txt">
                  {{ res.basic.term }}
                </p>
              </div>
              <div>
                <p class="res_main_subtitle">
                  Class
                </p>
                <p class="res_main_txt">
                  {{ res.basic.studentClass }}
                </p>
              </div>
              <div>
                <p class="res_main_subtitle">
                  Date Of Birth
                </p>
                <p class="res_main_txt">
                  {{ res.basic.dateOfBirth }}
                </p>
              </div>
              <div>
                <p class="res_main_subtitle">
                  Session
                </p>
                <p class="res_main_txt">
                  {{ res.basic.session }}
                </p>
              </div>
              <div>
                <p class=" res_main_subtitle">
                  CL. Teacher
                </p>
                <p class="res_main_txt">
                  {{ res.basic.classTeacher }}
                </p>
              </div>
              <div class="span2">
                <p class="res_main_subtitle">
                  Parent
                </p>
                <p class="res_main_txt">
                  {{ res.basic.parentName }}
                </p>
              </div>
            </div>
          </VCard>
          <div class="res_content_result">
            <div
              v-for="res in resultData"
              :key="res.subject"
            >
              <div v-if="res.maximum !== ''">
                <VCard class="res_card shadow">
                  <p class="res_sub">
                    {{ res.subject }}
                  </p>

                  <div class="res_cont_result">
                    <div>
                      <p class="res_marks">
                        {{ res.obtained }}/{{ res.maximum }}
                      </p>
                    </div>
                    <div>
                      <VProgressCircular
                        :value="res.percent"
                        :color="res.color"
                        :size="42"
                        :width="5"
                        class="ma-0 pa-0"
                      >
                        <span class="subtxt">
                          {{ res.percent }}
                        </span>
                      </VProgressCircular>
                    </div>
                  </div>
                  <div
                    v-if="res.grade !== ''"
                    class="res_grade"
                  >
                    Grade: {{ res.grade }}
                  </div>
                  <div
                    v-else
                    class="mt-4"
                  />
                </VCard>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <div>
      <VDialog
        v-model="isLoading"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_fs elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="white"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_fs elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_fs elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    noResult: false,
    isLoading: true,
    loadStatus: '',
    retry: 0,
    res: {
      basic: {
        term: '',
        admNo: '',
        dateOfBirth: '',
        parentName: '',
        classTeacher: '',
        studentName: '',
        studentClass: '',
        session: '',
      },
    },
    resultData: [],
  }),

  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      const { id } = this.$route.params;
      await axios.get(`${api}/result/${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.res = res.data.data;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
      this.res.result.forEach((el) => {
        if (el.maximum !== '') {
          this.resultData.push(el);
        }
      });

      this.isLoading = false;
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Result/view';
</style>
